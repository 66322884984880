<template>
    <div class="yj-conten-div">
        <a-layout style="height:100%;">
            <a-layout-content class="yj-conten-div_cont">
                <div class="yj-conten" style="min-height:calc(100% - 48px)">
                    <a-layout style="height:100%;">
                        <a-layout-header style="background: #f0f2f5;margin-bottom:14px;padding:0;height:auto">
                            <a-row :gutter="16">
                                <a-col class="gutter-row" :span="10">
                                    <order-detail :OrderID.sync="OrderID" v-if="RefreshOrder" @orderFunc="GetRefreshMoney"></order-detail>
                                </a-col>
                                <a-col class="gutter-row" :span="7">
                                    <store-detail :OrderID.sync="OrderID"></store-detail>
                                </a-col>
                                <a-col class="gutter-row" :span="7">
                                    <buyer-detail v-bind:OrderID.sync="OrderID"></buyer-detail>
                                </a-col>
                            </a-row>
                        </a-layout-header>
                        <a-layout-content style="height:100%;">
                            <money-detail v-bind:OrderID.sync="OrderID" @moneyFunc="GetRefreshOrder" v-if="RefreshMoney"></money-detail>
                        </a-layout-content>
                    </a-layout>
                </div>
            </a-layout-content>
        </a-layout>
    </div>
</template>
<script>
    import buyerDetail from "./components/buyerDetail";
    import orderDetail from "./components/orderDetail";
    import storeDetail from "./components/storeDetail";
    import moneyDetail from "./components/moneyDetail";
    export default {
        mame: "OrderDetail",
        data() {
            return {
                OrderID: "",
                RefreshOrder: true,
                RefreshMoney:true

            };
        },
        components: {
            buyerDetail,
            orderDetail,
            storeDetail,
            moneyDetail
        },
        methods: {
            //方法
            async getParams() {
                this.OrderID = this.$route.query.ID;
            },
            GetRefreshOrder(data) {
                this.RefreshOrder = data
                setTimeout(() => {
                    this.RefreshOrder = !this.RefreshOrder
                },100)
            },
            GetRefreshMoney(data) {
                this.RefreshMoney = data
                setTimeout(() => {
                    this.RefreshMoney = !this.RefreshMoney
                },100)
            }
        },
        mounted() {
        },
        created: function () {
            this.getParams();
        }
    };
</script>
<style scoped>
    .ant-card-body p {
        margin-bottom: 15px;
    }
</style>
