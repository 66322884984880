<template>
    <div class="gutter-box" style="box-shadow:none;height:365px">
        <a-card title="订单详情" :bordered="false">
            <p>订单号：{{OrderDetail.OrderID}}</p>
            <p>订单状态：{{ShowEnum(OrderDetail.PayState)}}</p>
            <p>
                订单金额：<a class="textcl_red">￥{{OrderDetail.PayMoney}}</a>
            </p>
            <p v-if="OrderDetail.PayState==4">作废原因：{{OrderDetail.FailReason}}</p>
            <p>下单时间：{{OrderDetail.InputTime}}</p>
            <p v-if="OrderDetail.PayState!=0">支付时间：{{OrderDetail.PayTime}}</p>
            <p v-if="OrderDetail.PayState!=0">微信单号：{{OrderDetail.PayProviderNO}}</p>
            <p>介绍人：<a @click="Partner">{{OrderDetail.Name}}</a></p>
            <p style="margin:0;text-align:right;" v-if="OrderDetail.PayState == 1 && OrderDetail.SettlementStatus== 0">
                <a-button type="default" size="small" @click="offlineback">作废</a-button>
            </p>
        </a-card>

        <a-modal title="作废"
                 :visible="visible"
                 @ok="handleOk"
                 @cancel="handleCancel"
                 okText="确认"
                 cancelText="取消">
            <p>
                <a-input placeholder="请输入作废原因" v-model="Remark" type="textarea" style="height:100px;" />
            </p>
        </a-modal>
       

    </div>
</template>
<script>
    import http from "@/Plugin/Http.js";
    import util from "@/Plugin/util.js";
    export default {
        name: "orderDetail",
        data() {
            return {
                OrderDetail: [],
                o_OrderID: this.OrderID,
                visible: false,
                Remark: "",
                PaystatusEnum: [],
                
            };
        },
        props: {
            //组件属性
            OrderID: String,
           
        },

        methods: {

            //方法
            handleCancel() {
                var self = this;
                self.Remark = "";
                self.visible = false;
              
            },
            handleOk() {
                var self = this;
                  
               
                var op = {
                    url: "/ShopModule/Order/OrderRefund",
                    data: {
                        OrderID: self.o_OrderID,
                        Remark: self.Remark
                    },
                    OnSuccess: function (res) {
                        if (res.data) {
                            self.$message.success("作废操作成功");
                            self.visible = false;
                            self.$emit("orderFunc",false)
                            self.getOrderDetail();
                        } else {
                            self.$message.error("作废操作失败");
                            self.$router.push({
                                name: "Order_OrderList"
                            })
                        }
                    }
                };
                http.Post(op);
            },
            offlineback() {
    
                this.visible = !this.visible
            },

            getOrderDetail() {
                var self = this;
                var op = {
                    url: "/ShopModule/Order/GetOrderInfo",
                    data: {
                        OrderID: self.o_OrderID
                    },
                    OnSuccess: function (res) {
                        if (!util.isBlank(res.data)) {
                            res.data.InputTime = util.TimeStamptoDateTime(res.data.InputTime, 'yyyy-MM-dd hh:mm:ss')
                            res.data.PayMoney = parseFloat(res.data.PayMoney).toFixed(2);
                            if (res.data.PayState != 0) {
                                res.data.PayTime = res.data.PayTime && util.TimeStamptoDateTime(res.data.PayTime, 'yyyy-MM-dd hh:mm:ss') || "";
                            }
                            self.OrderDetail = res.data;
                        } else {
                            self.$message.error("订单不存在！");
                        }
                    }
                };
                http.Post(op);
            },
            getPayStatusEnum: function () {//获取枚举信息
                var Self = this;
                var op = {
                    url: "/Enum",
                    data: {
                        AssemblyName: "Welcome.OrderModule",//程序集名称
                        TypeName: "Welcome.OrderModule.Enumerate.PayStateEnum"//枚举类型名称。要带上命名空间的全称
                    },
                    OnSuccess: function (response) {
                        Self.PaystatusEnum = response.data

                    }
                }
                http.Post(op);
            },
            ShowEnum: function (Key) {//枚举从0开始，且连续，可以使用此方法，效率高
                var Self = this;
                if (Self.PaystatusEnum[Key])
                    return Self.PaystatusEnum[Key].Name
                else
                    return "";
            },
            Partner() {
                var self = this;
                self.$router.push({
                    name: "PartnerList_PartnerDetail",
                    query: {
                        ID: self.OrderDetail.IntroducerID
                    }
                })
            }
        },
        computed: {
            //计算属性
        },
  
        components: {
            //组件
        },
        beforeCreate: function () {
            //生命周期，创建前
        },
        created: function () {

        },
        beforeMount: function () {
            //生命周期，组装前
        },
        mounted: function () {
            //生命周期，组装完成
            this.getOrderDetail();
            this.getPayStatusEnum();
        },
        beforeUpdate: function () {
            //生命周期，data更新前
        },
        updated: function () {
            //生命周期，data更新完成
        },
        beforeDestroy: function () {
            //生命周期，销毁前
        },
        destroyed: function () {
            //生命周期，销毁完成
        }
    };
</script>