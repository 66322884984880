<template>
    <div class="gutter-box" style="box-shadow:none;height:365px;">
        <a-card title="买家信息" :bordered="false">
            <a slot="extra" @click="buyerDetailInfo" v-if="isShowDetail">详情</a>
            <div class="yj-orderds">
                <div class="yj-orderds-l">
                    <img :src="buyerDetail.BuyerImg" height="94" />
                </div>
                <div class="yj-orderds-c">
                    <div>
                         {{buyerDetail.BuyerName}}
                        <span v-if="isShowPhone">({{buyerDetail.BuyerPhone}})</span>
                    </div>
                </div>

            </div>
            <div class="yj-orderds-c">
                <p>
                    订单姓名：<a @click="buyerDetailInfo">{{buyerDetail.OrderName}}</a>
                </p>
                <p>
                    订单手机号：{{buyerDetail.OrderPhone}}
                </p>
                <p v-if="isShowIdCard">
                    订单身份证号：{{buyerDetail.OrderIDCard}}
                </p>
                <p v-if="isShowAddress">
                    订单地址：{{buyerDetail.OrderAddrss}}
                </p>
            </div>
        </a-card>
    </div>
</template>
<script>
    import http from "@/Plugin/Http.js";

    import util from "@/Plugin/util.js";

    export default {
        name: "buyerDetail",
        data() {
            return {
                buyerDetail: [],
                b_OrderID: this.OrderID,//订单ID
                b_BuyerID: "", //购买者ID
                isShowDetail: true,
                isShowPhone:false,//是否显示手机号
                isShowAddress: false, //是否显示地址。为空时不显示
                isShowIdCard:false
            };
        },
        props: {
            // //组件属性
            OrderID: {
                type: String,
                default: ""
            }
        },
        methods: {
            //订单名称跳转到客户详情
            buyerDetailInfo() {
                var self = this;
                self.$router.push({
                    name: "OperationsDetail",
                    query: {
                        ID: self.b_BuyerID
                    }
                })
            },
            GetBuyerInfo() {
                var self = this;
                var op = {
                    url: "/ShopModule/Order/GetBuyerInfo",
                    data: {
                        OrderID: this.b_OrderID
                    },
                    OnSuccess: function (res) {
                        if (res.data != []) {
                            self.isShowDetail = true;
                            res.data.BuyerPhone =
                                res.data.BuyerPhone.length != 11 ? "" : res.data.BuyerPhone;
                            self.isShowPhone = util.isBlank(res.data.BuyerPhone) ? false : true;
                            console.log(util.isBlank(res.data.BuyerPhone))
                            console.log( self.isShowPhone)

                            self.isShowAddress = util.isBlank(res.data.OrderAddress) ? false : true
                            self.isShowIdCard = util.isBlank(res.data.OrderIDCard) ? false:true
                            self.b_BuyerID = res.data.BuyerID //购买者ID
                            self.buyerDetail = res.data;
                        } else {
                            self.isShowDetail = false;
                        }
                    }
                };
                http.Post(op);
            }
        },
        computed: {
            //计算属性
        },
        watch: {
            //侦听属性
        },
        components: {
            //组件
        },
        beforeCreate: function () {
            //生命周期，创建前
        },
        created: function () {

        },
        beforeMount: function () {
            //生命周期，组装前
        },
        mounted: function () {
            //生命周期，组装完成
            this.GetBuyerInfo();
        },
        beforeUpdate: function () {
            //生命周期，data更新前
        },
        updated: function () {
            //生命周期，data更新完成
        },
        beforeDestroy: function () {
            //生命周期，销毁前
        },
        destroyed: function () {
            //生命周期，销毁完成
        }
    };
</script>