<template>
  <div class="gutter-box" style="box-shadow:none;height:365px;">
    <a-card title="商品信息" :bordered="false">
      <div class="yj-orderds">
        <div class="yj-orderds-l">
          <img :src="storeDetail.ProductImg" height="94" />
        </div>
        <div class="yj-orderds-c">
          <div>{{storeDetail.ProductName}}</div>
          <div></div>
          <div>小计</div>
        </div>
        <div class="yj-orderds-r">
          <div>￥{{storeDetail.ProductMoneny}}</div>
          <div>X {{storeDetail.ProductCount}}</div>
          <div class="textcl_red">￥{{totalMoney}}</div>
        </div>
      </div>
    </a-card>
  </div>
</template>
<script>
    import http from "@/Plugin/Http.js";
    import util from "@/Plugin/util.js"

export default {
  name: "storeDetail",
  data() {
    return {
        storeDetail:[],
        s_OrderID: this.OrderID,
      
    };
  },
  props: {
    //组件属性
      OrderID: String,
  },
   methods: {
    // //方法
    getstoreDetail() {
      var self = this;
      var op = {
        url: "/ShopModule/Order/GetStoreInfo",
        data: {
          OrderID: this.s_OrderID
        },
        OnSuccess: function(res) {
            if (res.data != []) {
            self.storeDetail = res.data;
          }
        }
      };
      http.Post(op);
    }
  },
  computed: {
      totalMoney: function () {
          if (util.isBlank(this.storeDetail.ProductMoneny)) {
              return 0;
          } else {
                 return parseFloat(
        this.storeDetail.ProductMoneny * this.storeDetail.ProductCount
      ).toFixed(2);
          }
   
    }
  },
  watch: {
    //侦听属性
  },
  components: {
    //组件
  },
  beforeCreate: function() {
    //生命周期，创建前
  },
  created: function() {
    // this.getParams();
  },
  beforeMount: function() {
    //生命周期，组装前
  },
  mounted: function() {
    //生命周期，组装完成
    this.getstoreDetail();
  },
  beforeUpdate: function() {
    //生命周期，data更新前
  },
  updated: function() {
    //生命周期，data更新完成
  },
  beforeDestroy: function() {
    //生命周期，销毁前
  },
  destroyed: function() {
    //生命周期，销毁完成
  }
};
</script>