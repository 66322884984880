<template>
    <a-layout style="padding:0px 20px 0  20px ;background-color:#fff;height:100%">
        <a-layout-header style="background: #fff; padding: 0;height:48px;line-height:48px">佣金记录</a-layout-header>
        <a-layout-content style="height:100%;">
            <a-table :columns="columns"
                     :data-source="OrderMoneyDetail"
                     :pagination="false"
                     rowKey="ID"
                     style="min-height:calc(100vh - 601px)">
                <span slot="img" slot-scope="img">
                    <img width="64" :key="img" :src="img" />
                </span>
                <span slot="BussinessType" slot-scope="text, record">{{ ShowMoneyTypeEnum(record.BussinessType) }}</span>
                <span slot="settleStatus" slot-scope="text, record">
                    {{ ShowSettlementStatusEnum(record.settleStatus) }}
                </span>
            </a-table>
        </a-layout-content>
        <a-layout-footer style="background:#fff;padding:10px 0 0;" v-if="OrderMoneyDetail.length >0 && isshowSettle">
            <a-row>
                <a-col :span="24" class="textal_r">
                    <div style="padding:10px 0 ">
                        <a-row type="flex" justify="space-between">
                            <a-col :span="1">
                                <a-button type="primary" class="margin-r" ghost @click="batchgrant">
                                    结算
                                </a-button>
                                <a-modal title="结算"
                                         :visible="visible"
                                         @ok="handleOk"
                                         @cancel="handleCancel"
                                         okText="确认"
                                         cancelText="取消">
                                    <p>
                                        <a-radio-group v-model="SettleMode">
                                            <a-radio :value="1">
                                                线下
                                            </a-radio>
                                            <a-radio :value="0">
                                                线上
                                            </a-radio>
                                        </a-radio-group>
                                        <a-input placeholder="备注~" v-model="Remark" type="textarea" style="margin-top:20px;" />
                                    </p>
                                </a-modal>
                            </a-col>
                        </a-row>
                    </div>
                </a-col>
            </a-row>
        </a-layout-footer>
    </a-layout>
</template>
<script>
    import http from "@/Plugin/Http.js";

    export default {
        name: "moneyDetail",
        data() {
            return {
                columns: [
                    {
                        title: "团队",
                        key: "Name",
                        dataIndex: "Name",
                        width: 300
                    },
                    {
                        title: "佣金类型",
                        dataIndex: "BussinessType",
                        key: "BussinessType",
                        scopedSlots: { customRender: "BussinessType" },
                        width: 200
                    },
                    {
                        title: "金额",
                        dataIndex: "Money",
                        key: "Money",
                        width: 160
                    },
                    {
                        title: "结算状态",
                        key: "settleStatus",
                        dataIndex: "settleStatus",
                        scopedSlots: { customRender: "settleStatus" },
                        width: 200
                    }
                ],
                OrderMoneyDetail: [],
                m_OrderID: this.OrderID,
                visible: false,
                Remark: '',
                SettleMode: 1,
                SettleStatus: "0",
                moneyOrderID: "",
                MoneyTypeEnum: [],
                SettlementStatusEnum: [],
                isshowSettle: false,//是否显示结算按钮
                //当佣金记录结算成功后，已支付的订单就不能发起退款，传递变量，刷新订单详情
                Refresh: false,//刷新订单
            };
        },
        props: {
            //组件属性
            OrderID: {
                type: String,
                default: ""
            },
            //当佣金记录结算成功后，已支付的订单就不能发起退款，传递变量，刷新订单详情

        },
        methods: {
            //方法
            handleOk() {
                if (this.SettleMode == 1) {
                    this.offlineSettlement()
                } else {
                    this.onlineSettlement()
                }
            },
            onlineSettlement() {
                var self = this;
                var op = {
                    url: "/ShopModule/Order/OnlineSettlement",
                    data: {
                        settlementID: self.moneyOrderID,
                        Remark: self.Remark,
                    },
                    OnSuccess: function (data) {
                        console.log(data)
                        self.visible = false;
                        self.getMoneyList();
                    }
                };
                http.Post(op);
            },
            offlineSettlement() {
                var self = this;
                var op = {
                    url: "/ShopModule/Order/OfflineSettlement",
                    data: {
                        settlementID: self.moneyOrderID,
                        Remark: self.Remark,
                    },
                    OnSuccess: function (res) {
                        if (res.data) {
                            self.$message.success("佣金结算成功");
                            self.$emit('moneyFunc', self.Refresh)
                            self.getMoneyList();

                        } else {
                            self.$message.error("佣金结算失败");
                            self.$router.push({
                                name: "Order_OrderList"
                            })
                        }
                        self.visible = false;
                    }
                };
                http.Post(op);
            },
            handleCancel() {
                var self = this;
                self.visible = false;
            },
            batchgrant: function () {
                this.visible = true;
            },
            getMoneyList() {
                var self = this;
                var op = {
                    url: "/ShopModule/Order/OrderMoneyList",
                    data: {
                        orderID: self.m_OrderID
                    },
                    OnSuccess: function (res) {

                        if (res.data.length > 0) {
                            res.data.forEach(element => {
                                element.Money = parseFloat(element.Money).toFixed(
                                    2
                                );
                            });
                            self.OrderMoneyDetail = res.data;
                            self.moneyOrderID = res.data[0].OrderID
                            if (res.data[0].settleStatus == 0 && res.data[0].payState == 1) {
                                self.isshowSettle = true
                            } else {
                                self.isshowSettle = false
                            }

                        }
                    }
                };
                http.Post(op);
            },
            getMoneyTypeEnum: function () {//获取枚举信息
                var Self = this;
                var op = {
                    url: "/Enum",
                    data: {
                        AssemblyName: "Welcome.OrderModule",//程序集名称
                        TypeName: "Welcome.OrderModule.Enumerate.MoneyEnum"//枚举类型名称。要带上命名空间的全称
                    },
                    OnSuccess: function (response) {
                        Self.MoneyTypeEnum = response.data

                    }
                }
                http.Post(op);
            },
            getSettlementStatusEnum: function () {//获取枚举信息
                var Self = this;
                var op = {
                    url: "/Enum",
                    data: {
                        AssemblyName: "Welcome.OrderModule",//程序集名称
                        TypeName: "Welcome.OrderModule.Enumerate.SettlementEnum"//枚举类型名称。要带上命名空间的全称
                    },
                    OnSuccess: function (response) {
                        Self.SettlementStatusEnum = response.data
                    }
                }
                http.Post(op);
            },
            ShowSettlementStatusEnum: function (Key) {//枚举不是从0开始，或不连续，可以使用此方法
                var Self = this;
                var item = Self.SettlementStatusEnum.find(function (item) {
                    return item.ID == Key;
                });
                if (item)
                    return item.Name
                else
                    return "";
            },
            ShowMoneyTypeEnum: function (Key) {//枚举不是从0开始，或不连续，可以使用此方法
                var Self = this;
                var item = Self.MoneyTypeEnum.find(function (item) {
                    return item.ID == Key;
                });
                if (item)
                    return item.Name
                else
                    return "";
            }
        },
        computed: {
            //计算属性
        },
        watch: {
            //侦听属性
        },
        components: {
            //组件
        },
        beforeCreate: function () {
            //生命周期，创建前
        },
        created: function () {
            //生命周期，创建完成
        },
        beforeMount: function () {
            //生命周期，组装前
        },
        mounted: function () {
            this.getMoneyList();
            this.getMoneyTypeEnum();
            this.getSettlementStatusEnum();
        },
        beforeUpdate: function () {
            //生命周期，data更新前
        },
        updated: function () {
            //生命周期，data更新完成
        },
        beforeDestroy: function () {
            //生命周期，销毁前
        },
        destroyed: function () {
            //生命周期，销毁完成
        }
    };
</script>
